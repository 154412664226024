import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    "menu-id": "app-menu",
    "content-id": "main-content",
    side: "end"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, {
            class: "mainMenu",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuNavigation('/home')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Home")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menuNavigation('/profile')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Profile")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.menuNavigation('/mileage')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Mileage Tracker")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.menuNavigation('/find-my-nearest')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Find my Nearest")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.menuNavigation('/driving-tips')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Driving Tips")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.menuNavigation('/driving-assistance')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Driving Assistance")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.menuNavigation('/cms-page/what-to-do-in-an-accident')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("What to do in an Accident")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.menuNavigation('/buy-my-car')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Buy my Car")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.menuNavigation('/book-rental')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Book a Rental")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.menuNavigation('/cms-page/tch-leasing')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("TCH Leasing")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.menuNavigation('/cms-page/terms-conditions')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Terms & Conditions")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}